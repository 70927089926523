@use "../abstracts" as *
@use "sass:math"
//----------------------------------
//             BUTTONS             -
//----------------------------------

.button, .wp-block-button
	margin: 1rem 0

	a, .button-link, .wp-block-button__link
		background: $lightPrimary
		color: $darkLight
		padding: 1rem
		margin: 1rem 0
		border-radius: math.div($borderRadiusSize, 4)
		cursor: pointer
		border: 2px solid white
		-webkit-box-shadow: 0 0 20px -6px rgba(0,0,0,0.75)
		-moz-box-shadow: 0 0 20px -6px rgba(0,0,0,0.75)
		box-shadow: 0 0 20px -6px rgba(0,0,0,0.75)
		transition: $transition-default
		text-decoration: none


		&:hover, &:focus
			text-decoration: none
			-webkit-box-shadow: 0 0 1px 4px rgba($primary,0.75)
			-moz-box-shadow: 0 0 1px 4px rgba($primary,0.75)
			box-shadow: 0 0 1px 4px rgba($primary,0.75)
			outline: none

	&.font--big, &.is-style-font--big
		font-size: 1.3rem



	//Additional shadow
	&.shadow, &.is-style-shadow .wp-block-button__link
		-webkit-box-shadow: 0 0 20px -6px rgba(0,0,0,0.75)
		-moz-box-shadow: 0 0 20px -6px rgba(0,0,0,0.75)
		box-shadow: 0 0 20px -6px rgba(0,0,0,0.75)
		transition: $transition-default

		&:hover, &:focus
			-webkit-box-shadow: 0 0 1px 4px rgba($primary,0.75)
			-moz-box-shadow: 0 0 1px 4px rgba($primary,0.75)
			box-shadow: 0 0 1px 4px rgba($primary,0.75)
			outline: none
			//-webkit-box-shadow: 0 0 20px -3px rgba(0,0,0,0.75)
			//-moz-box-shadow: 0 0 20px -3px rgba(0,0,0,0.75)
			//box-shadow: 0 0 20px -3px rgba(0,0,0,0.75)


	&.shadow--hover, &.is-style-shadow--hover .wp-block-button__link
		transition: $transition-default
		&:focus
			-webkit-box-shadow: 0 0 1px 4px rgba($primary,0.75)
			-moz-box-shadow: 0 0 1px 4px rgba($primary,0.75)
			box-shadow: 0 0 1px 4px rgba($primary,0.75)
			outline: none

	// Grey/NotActive Button
	&.grey, &.notActive, &.is-style-grey .wp-block-button__link
		background: $lightPrimary
		color: $darkLight
		border: none
		padding: .5rem
		margin: 1rem
		border-radius: math.div($borderRadiusSize, 4)
		cursor: pointer

	//Lightgrey Button
	&.lightgrey, &.is-style-lightgrey .wp-block-button__link
		background: $lightBackground
		color: $darkLight
		border: none
		padding: .5rem
		margin: 1rem
		border-radius: math.div($borderRadiusSize, 4)
		cursor: pointer

	// Additional White border
	&.whiteBorder, &.border--white, &.is-style-whiteborder .wp-block-button__link
		border: 2px solid white

	// Active State
	&.active
		color: $primary !important
		background: $secondary !important
	//Primary bg-color
	&.primary, &.active, &.selected, &.is-style-primary .wp-block-button__link
		background: $primary
		color: $textLight
		border: none
		padding: 1rem 1.5rem
		margin: 1rem
		border-radius: $borderRadiusHalf
		cursor: pointer

	&.primary--hover
		transition: $transition-default
		&:hover, &:focus
			background: $primaryHover
			outline: none


	//Primary bg-color
	&.secondary, &.is-style-secondary .wp-block-button__link
		background: $secondary
		color: $textDark
		border: none
		padding: 1rem 1.5rem
		margin: 1rem
		border-radius: $borderRadiusHalf
		cursor: pointer

	&.secondary--hover
		transition: $transition-default
		&:hover
			background: $secondaryHover

	//Primary font color
	&.color--primary, .arrow--primary, &.is-style-color--primary .wp-block-button__link
		color: $primary

	&.typeform-style
		background: rgba(4, 69, 175, 0.1)
		box-shadow: rgb(4 69 175 / 60%) 0px 0px 0px 1px inset
		color: rgb(4, 69, 175)
		max-width: 100%
		min-width: 75px
		width: 168px
		min-height: 40px
		outline: 0px
		padding: 4px
		border-radius: 4px
		border: none
		margin: 0
		cursor: pointer
		transition: all ease .1s
		font-size: 1.1rem

		&:hover, &:focus
			background: rgba(4, 69, 175, 0.3)

	// Primary w/  shadow
	&.is-style-primary-shadow .wp-block-button__link
		border: 2px solid $primary
		background: $primary
		color: $textLight
		padding: .5rem
		margin: 1rem
		border-radius: math.div($borderRadiusSize, 4)
		cursor: pointer

		-webkit-box-shadow: 0 0 20px -6px rgba(0,0,0,0.75)
		-moz-box-shadow: 0 0 20px -6px rgba(0,0,0,0.75)
		box-shadow: 0 0 20px -6px rgba(0,0,0,0.75)
		transition: $transition-default

		&:hover, &:focus
			-webkit-box-shadow: 0 0 1px 4px rgba($primary,0.75)
			-moz-box-shadow: 0 0 1px 4px rgba($primary,0.75)
			box-shadow: 0 0 1px 4px rgba($primary,0.75)
			outline: none

	// Lightgrey w/ border & shadow
	&.is-style-lightgrey-border-shadow .wp-block-button__link
		border: 2px solid white
		background: $lightBackground
		color: $darkLight
		padding: .5rem
		margin: 1rem
		border-radius: math.div($borderRadiusSize, 4)
		cursor: pointer

		-webkit-box-shadow: 0 0 20px -6px rgba(0,0,0,0.75)
		-moz-box-shadow: 0 0 20px -6px rgba(0,0,0,0.75)
		box-shadow: 0 0 20px -6px rgba(0,0,0,0.75)
		transition: $transition-default

		&:hover, &:focus
			-webkit-box-shadow: 0 0 1px 4px rgba($primary,0.75)
			-moz-box-shadow: 0 0 1px 4px rgba($primary,0.75)
			box-shadow: 0 0 1px 4px rgba($primary,0.75)
			outline: none

	&.margin--inline--small
		margin-left: .5rem
		margin-right: .5rem

	&.color--secondary
		color: $secondary

	.language
		position: absolute
		top: 0.3rem
		right: 0.3rem
		font-size: .8rem




.button--primary--shadow
	background: $primary
	color: $textLight
	border: none
	padding: .5rem
	margin: 1rem 0
	border-radius: math.div($borderRadiusSize, 4)
	cursor: pointer

	-webkit-box-shadow: 0 0 20px -6px rgba(0,0,0,0.75)
	-moz-box-shadow: 0 0 20px -6px rgba(0,0,0,0.75)
	box-shadow: 0 0 20px -6px rgba(0,0,0,0.75)
	transition: $transition-default

	&:hover, &:focus
		-webkit-box-shadow: 0 0 1px 4px rgba($primary,0.75)
		-moz-box-shadow: 0 0 1px 4px rgba($primary,0.75)
		box-shadow: 0 0 1px 4px rgba($primary,0.75)
		outline: none

