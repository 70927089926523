@use "../abstracts" as *
@use "../layouts" as *

//----------------------------------
//          Results Table          -
//----------------------------------

.downloadPDF--button
	cursor: pointer
	&:hover, &:focus
		color: $secondary
		svg, path
			color: $secondary
			fill: $secondary

.allResults
	overflow-x: scroll
	table.allResults__table
		background: white
		border-collapse: collapse
		width: 100%
		tr
			border-bottom: 1px solid black
			&:nth-child(even)
				background-color: #f2f2f2
		th
			background: #ddd

		th, td
			text-align: left
			padding: .5rem 1rem
			border: 1px solid #ddd

			&.allResults__sumBlue
				color: blue
			&.allResults__sumGreen
				color: green
			&.allResults__sumRed
				color: red
			&.allResults__sumYellow
				color: #d0c648
			&.allResults__sumOrange
				color: orange

			&[class^="allResults__"]
				width: 50px