@use "../abstracts" as *
@use "../layouts" as *

//----------------------------------
//              FOOTER             -
//----------------------------------

#footer
	@include safearea()
	position: absolute
	left: 0
	bottom: .5rem //1rem
	//width: 100%
	width: 100%

	@include media-below(tablet)
		bottom: 0

	a
		color: black
		text-decoration: none
		border-bottom: 1px solid transparent
		transition: all 0.3s ease
		&:hover
			border-bottom: 1px solid $primary


