@use "../abstracts" as *
@use "../layouts" as *

//----------------------------------
//              FRONT              -
//----------------------------------


.typeform-results
	@extend .flex
	@extend .flex-center--vertical
	@extend .flex-center--horizontal
	background: transparent //$secondary
	//display: none

	&.show
		display: flex

	.typeform-results__inner
		text-align: center
		width: 60%
		max-width: $content
		padding: 1rem

		@include media-below(tablet)
			width: 80%

		@include media-below(phone)
			width: 100%

		.button
			margin: 1rem 0

		.typeform-results__loading, .typeform-results__eval
			@extend .flex
			@extend .flex-center--vertical
			@extend .flex-center--horizontal
			@extend .flex-direction--column
			@extend .flex--gap
			display: none
			width: 100%

			&.show
				display: flex

			.result__details
				display: none

			.results__information
				text-align: left

			.result-colored-container
				width: 100%
				height: 40px
				background: cornflowerblue
				margin: 1rem 0

				&.green
					background: green
				&.yellow
					background: yellow
				&.orange
					background: orange
				&.red
					background: red