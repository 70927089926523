@use "../abstracts" as *

//----------------------------------
//            NAVIGATION           -
//----------------------------------


video
    border: 1px solid grey
    max-width: 100%

