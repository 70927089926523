@use "../abstracts" as *

//----------------------------------
//           TEXT STYING           -
//----------------------------------

.uppercase
	text-transform: uppercase

.color--dark
	color: $textDark

.color--light
	color: $textLight

.text--center, .align--center
	text-align: center

.strong, .bold
	font-weight: 800



/**** FONT ****/
h1
	color: $primary
	font-family: Calibri, sans-serif
	font-size: 3.2rem
	font-weight: 800

h2
	color: $textDark
	font-family: Calibri, sans-serif
	font-size: 2rem
	font-weight: 500


	&.center
		text-align: center
	&.small
		font-size: 2.2rem

	&.white
		color: white

h3
	&.center
		text-align: center
	&.white
		color: white




h1, h2, h3, h4, h5
	&.padding--block
		padding-block: 1rem

	&.padding--block--small
		padding-block: .5rem

	&.center
		text-align: center

	&.margin--none
		margin: 0 !important

p
	padding: .5rem 0
	//font-weight: 200

	&.paddingVertical, &.is-style-padding-vertical
		padding: 1rem 0

	&.center, &.is-style-center
		text-align: center

	&.left, &.is-style-left
		text-align: left

	&.white, &.is-style-white
		color: white

	&.light, &.is-style-light
		font-weight: 200

	&.color--lighter
		color: $lightgrey

	&.center
		text-align: center

a
	text-decoration: none

	&:hover
		text-decoration: underline

main a
	color: $primary

.bigger-paragraph
	font-size: 1.2rem

.align--left, .is-style-align--left
	text-align: left
