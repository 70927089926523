@use "../abstracts" as *
@use "sass:math"
//----------------------------------
//             BUTTONS             -
//----------------------------------


input, textarea
	padding: .5rem
	border-radius: $borderRadiusHalf
	border: 1px solid $primary
	font-family: Calibri, sans-serif

.successMessage
	color: forestgreen

.errorMessage
	color: darkred

.hide
	display: none

.show
	display: block