@use "../abstracts" as *

//----------------------------------
//              GLOBAL             -
//----------------------------------


*
	margin: 0
	padding: 0
	box-sizing: border-box


html, body
	margin: 0
	padding: 0
	font-size: 18px
	font-family: Calibri, sans-serif
	overflow-x: hidden
	background: $secondary
	background: url("/assets/images/background2.jpg") no-repeat center
	background-size: cover
	background-attachment: fixed

	&.lighter
		background: url("/assets/images/background2-lighter.jpg") no-repeat center
		background-size: cover


.global
	width: 100vw
	max-width: 100%
	height: 100%
	min-height: 100vh

	position: relative
	//background: url("../../assets/images/background2.jpg") no-repeat center
	//background-size: cover

	&.noImage
		background: rgba(255,255,255,0.5)

main
	min-height: 100vh
	height: auto
	width: 100vw

	&.statisticsPage
		min-height: initial
		width: 100%
		padding: 100px 1rem 3rem 1rem

	&.normalPage
		min-height: initial
		width: 100%
		padding: 100px 1rem 3rem 1rem


		.content
			background: white
			padding: 2rem
			margin-bottom: 3rem

			@include media-below(phone)
				padding: 1rem

		img
			max-width: 300px

		a
			color: $primary

.content
	width: 100%
	padding: 1rem
	max-width: $content
	margin: 0 auto

	&.padding-inline--none
		@include media-above(content)
			padding: 1rem 0

		@include media-below(tablet)
			padding: 1rem 0

	&--no-padding, &__padding--none
		padding: 0

	&.marginTop--normal, .marginTop--normal
		margin-top: 2rem

	&.marginTop, .marginTop
		margin-top: 4rem

	&.padding--bottom
		padding-bottom: 3rem

	&.background--white
		background: white

	&.border-radius
		border-radius: $borderRadiusHalf

.small-content
	width: 60%
	&--no-padding, &__padding--none
		padding: 0

	&.marginTop
		margin-top: 4rem

	&.padding--top
		padding-top: 2rem

	&.padding--bottom
		padding-bottom: 3rem

.wide-content
	width: 100%
	padding: 1rem 2rem
	margin: 0 auto

	&--no-padding, &__padding--none
		padding: 0

	&.marginTop
		margin-top: 4rem

	&.padding--bottom
		padding-bottom: 3rem

img
	width: 100%
	height: auto
	object-fit: cover

main
	ul, ol
		margin-left: 1.1rem

.relative
	position: relative