@use "sass:math"
//----------------------------------
//             VARIABLES           -
//----------------------------------


// COLORS

$white:             	white
$black:             	black
$lightgrey:         	lightgrey
$lightergrey:			#dcdcdc
$verylightgrey:     	#f3f3f3

$textLight:				white
$textDark:				black

$primary:             	#253D83
$primaryHover:      	darken($primary, 20%)
$primaryLighter: 		lighten($primary, 20%)

$secondary: 			#f1bb40
$secondaryHover:    	darken($secondary, 20%)

$third:             	#8c93a3

$accent:            	#F25E79

$lightPrimary: 			#dddddd
$lightBase:         	#ffffff
$lightBackground: 		#f5f5f8
$lightgrey: 			#949494

$darkTransparent:   	rgba(104, 104, 104, 0.3)
$darkPrimary:       	#212121
$darkBase:          	#303030
$darkCard:          	#424242
$darkLight: #757575

$navBackground:     	transparent //$primary

// SIZES
$headerHeight: 			800px
$headerSmallHeight:		112px
$navHeight:         	80px
$navWidth: 				500px
$navMobileHeight: 		78px
$borderRadiusSize:		16px
$borderRadiusHalf: 		math.div($borderRadiusSize, 2)
$borderRadius:      	$borderRadiusSize

$gridSpacing:       	2rem
$sliderHeight: 			470px
$hamburgerLineLength:	50px

$bigScreen:         	1920px
$contentWithMargin:		1464px
$content:           	1400px
$desktop:           	1080px
$tablet:            	920px
$phone:             	720px
$smallPhone:        	420px


// Z-Indexes
$zHeader: 				100
$zNavigation: 			200
$zNavigationHamburger: 	190
$zOverlay: 				100
$zPopup: 300

// Transitions
$transition-default: 	all .3s ease
