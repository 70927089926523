@use "../abstracts" as *
@use "../layouts" as *

//----------------------------------
//              FRONT              -
//----------------------------------


.typeform-startscreen
	@extend .flex
	@extend .flex-center--vertical
	@extend .flex-center--horizontal
	background: transparent //$secondary
	//display: none

	&.show
		display: flex

	&.genderscreen
		@extend .flex
		@extend .flex-center--vertical
		@extend .flex-direction--column
		justify-content: flex-start
		background: transparent //$secondary
		//display: none
		overflow-y: scroll
		max-height: calc(100vh - 18rem)
		flex-direction: column
		top: 1rem

		position: relative

		&.bigTop
			top: 10rem

	.typeform-startscreen__inner
		@extend .flex
		@extend .flex-center--vertical
		@extend .flex-center--horizontal
		@extend .flex-direction--column
		@extend .flex--gap
		text-align: center
		width: 60%
		max-width: $content

		@include media-below(tablet)
			width: 80%

		@include media-below(phone)
			width: 100%

		.typeform-startscreen__inner__text
			min-height: 310px

		h2
			font-size: 2.5rem
			font-weight: 900

			@supports (-webkit-text-stroke: 1px black)
				-webkit-text-stroke: 2px $primary
				-webkit-text-fill-color: transparent

			@include media-below(phone)
				font-size: 1.8rem

		h1
			font-weight: 800
			color: $primary
			@include media-below(phone)
				font-size: 2rem

		h3
			font-weight: 400
			//font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
			line-height: 1.5rem
		p, li
			font-weight: 400
			line-height: 1.5rem
			//font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
			&.bigger
				font-size: 1.3rem
				font-weight: 300
				line-height: 1.5

.typeform-introscreen
	@extend .flex
	@extend .flex-center--vertical
	@extend .flex-direction--column
	justify-content: flex-start
	background: transparent //$secondary
	//display: none
	overflow-y: scroll
	max-height: calc(100vh - 18rem)
	flex-direction: column
	top: 3.5rem

	position: relative

	&.show
		display: flex

	.typeform-introscreen__inner
		@extend .flex
		@extend .flex-direction--column
		@extend .flex--gap
		text-align: center
		width: 60%
		max-width: $content
		margin-top: -2rem


		@include media-below(tablet)
			width: 80%

		@include media-below(phone)
			width: 100%


		p, li
			font-weight: 600
			line-height: 1.5rem
			//font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
			&.bigger
				font-size: 1.3rem
				font-weight: 300
				line-height: 1.5

		li
			font-weight: 400

		button
			width: 150px
			margin: 0 auto

div[class^="footer__FooterWrapper"]
	background: none !important