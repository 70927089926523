@use "../abstracts" as *

.typeform-container
	height: auto
	width: 100%
	//display: none
	min-height: calc(100vh - 10rem) //calc(100vh - 8rem)
	padding: 5rem 1rem //0rem 1rem

	&.show
		display: block

	.typeform-widget, iframe
		height: auto
		width: 100%
		border: none
		min-height: calc(100vh - 11rem)

		h1[class^="text__TextWrapper"],
		footer[class^="branding__footer"],
		a[class^="footer__BrandButton"]
			display: none !important
			height: 0 !important
			width: 0 !important