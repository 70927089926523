@use "../abstracts" as *
@use "../layouts" as *
@use "sass:math"

.logo
	background: url("/assets/svgs/logo.svg") no-repeat
	background-size: 90%
	background-position: center
	height: 49px
	width: 70px

.logo-heading__link

	h1.logo-heading
		color: $primary
		font-family: 'Montserrat', sans-serif
		font-weight: 800
		font-size: 2.5rem
		border: 3px solid transparent

		@include media-below(phone)
			font-size: 1.6rem
			font-weight: 600
	&:hover, &:focus
		text-decoration: none
		outline: none
		.logo-heading
			color: $primaryHover

	&:focus
		.logo-heading
			border: 3px solid $primary
			border-radius: math.div($borderRadiusSize, 4)