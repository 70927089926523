//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;800;900&family=Work+Sans:wght@300;400;600&display=swap')

// font-family: 'Montserrat', sans-serif
// font-family: 'Work Sans', sans-serif

@font-face
	font-family: Montserrat
	src: url('/assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype')
	font-weight: 400

@font-face
	font-family: Montserrat
	src: url('/assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype')
	font-weight: 800

@font-face
	font-family: Calibri
	src: url('/assets/fonts/calibri/calibri-regular.ttf') format('truetype')
	font-weight: 400

@font-face
	font-family: Calibri
	src: url('/assets/fonts/calibri/calibri-light.ttf') format('truetype')
	font-weight: 100

@font-face
	font-family: Calibri
	src: url('/assets/fonts/calibri/calibri-bold.ttf') format('truetype')
	font-weight: 800

/** Italic **/
@font-face
	font-family: Calibri-Italic
	src: url('/assets/fonts/calibri/calibri-italic.ttf') format('truetype')
	font-weight: 400

@font-face
	font-family: Calibri-Italic
	src: url('/assets/fonts/calibri/calibri-light-italic.ttf') format('truetype')
	font-weight: 100

@font-face
	font-family: Calibri-Italic
	src: url('/assets/fonts/calibri/calibri-bold-italic.ttf') format('truetype')
	font-weight: 800
