@use "../var" as *
//––––––––––––––––––––––––––––––––––––––––––––––––––
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––

// A map of breakpoints.
$breakpoints: (smallPhone: $smallPhone, phone: $phone, tablet: $tablet, desktop: $desktop, content: $content, bigSceen: $bigScreen)


//––––––––––––––––––––––––––––––––––––––––––––––––––
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include media-above(phone)
@mixin media-above($breakpoint)
	@if map-has-key($breakpoints, $breakpoint)
		$breakpoint-value: map-get($breakpoints, $breakpoint)
		@media screen and (min-width: $breakpoint-value)
			@content
	@else
		@warn 'Invalid breakpoint: #{$breakpoint}.'




//––––––––––––––––––––––––––––––––––––––––––––––––––
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include media-below(phone)
@mixin media-below($breakpoint)
	@if map-has-key($breakpoints, $breakpoint)
		$breakpoint-value: map-get($breakpoints, $breakpoint)
		@media screen and (max-width: ($breakpoint-value - 1))
			@content
	@else
		@warn 'Invalid breakpoint: #{$breakpoint}.'



//––––––––––––––––––––––––––––––––––––––––––––––––––
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include media-between(phone, tablet)
@mixin media-between($lower, $upper)
	@if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper)
		$lower-breakpoint: map-get($breakpoints, $lower)
		$upper-breakpoint: map-get($breakpoints, $upper)
		@media screen and (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1))
			@content
	@else
		@if (map-has-key($breakpoints, $lower) == false)
			@warn 'Your lower breakpoint was invalid: #{$lower}.'
		@if (map-has-key($breakpoints, $upper) == false)
			@warn 'Your upper breakpoint was invalid: #{$upper}.'
