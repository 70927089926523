@use "../abstracts" as *
@use "../layouts" as *
@use "sass:math"

//----------------------------------
//            NAVIGATION           -
//----------------------------------

.navSpacer
	height: $navHeight

nav#mainNav
	background: transparent
	height: $navHeight
	color: white
	z-index: $zNavigation
	padding: 0 2rem
	position: absolute
	top: 0
	left: 0
	width: 100%

	@include safearea()

	@include media-below(tablet)
		padding: 0 1rem

	&.showBackground
		@include media-below(tablet)
			background: $primary
			.logo-heading
				color: white !important
			.mobileToggle
				background: white !important

	&.fixed
		position: fixed
		width: 100%
		transition: $transition-default

		&.hidden
			transform: translateY(-100%)



	.nav__wrapper
		height: $navHeight

		@include media-below(tablet)
			width: $navHeight

		input.mobileCheckbox
			width: $navHeight
			height: $navHeight
			display: none
			top: 0
			right: .5rem
			opacity: 0
			cursor: pointer
			-webkit-touch-callout: none
			z-index: 2

			position: absolute


			@include media-below(tablet)
				display: block

			@media screen and (orientation: landscape) and (max-width: $tablet)
				right: env(safe-area-inset-right)


		.nav__inner
			height: $navHeight

			@include media-below(tablet)
				opacity: 0
				visibility: hidden
				display: block
				transition: $transition-default
				height: auto
				width: 100%
				background: $primary
				top: $navHeight
				position: absolute
				left: 0
				z-index: 100
				border-bottom: 1px solid $white
				padding: 1rem 0



			ul, .mainNav__list
				@extend .flex
				@extend .flex--gap
				@extend .flex-center--vertical
				height: $navHeight

				@include media-below(tablet)
					flex-direction: column
					gap: 0
					height: auto
					width: 100%
					padding-left: 0

				li
					@extend .flex
					@extend .flex-center--vertical
					list-style: none
					position: relative
					overflow: hidden

					@include media-below(tablet)
						display: flex
						justify-content: flex-start
						flex-wrap: wrap
						flex-direction: row
						width: calc(100% - 2rem)
						height: calc(100% + 2rem)

					.navLink
						padding: .45rem .3rem
						color: $primary
						text-decoration: none
						overflow: hidden
						z-index: 0
						border: 3px solid transparent
						font-weight: 600
						font-size: 1.2rem

						@include pseudo($left: 0, $bottom: 0)
							height: 3px
							width: 50%
							background: $primary
							transform: translateX(-105%)
							overflow: hidden
							transition: $transition-default
							z-index: -1

						&:hover, &:focus, &.active
							&:before
								transform: translateX(0)
						&:focus
							border: 3px solid $primary
							outline: none
							border-radius: math.div($borderRadiusSize, 4)

						@include media-below(tablet)
							color: white




		span.mobileToggle
			width: 32px
			height: 3px
			top: 1rem
			right: 2rem
			position: absolute
			background: $primary //$white
			//border-radius: 3px
			z-index: 1
			transform-origin: 4px 0
			transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), opacity 0.55s ease

			margin-bottom: 5px
			//margin-left: calc(100% - 4rem)
			display: none


			@media screen and (max-width: $tablet)
				display: block


			&:first-of-type
				margin-top: .8rem
				transform-origin: 0% 0%


			&:nth-of-type(2)
				margin-top: 1.3rem
				transform-origin: 0% 100%


			&:nth-of-type(3)
				margin-top: 1.8rem




		input.mobileCheckbox:checked
			&~ span.mobileToggle
				opacity: 1
				transform: rotate(45deg) translate(0px, -6px)
				background: $primary //$white


			&~ span.mobileToggle:nth-of-type(2)
				opacity: 0
				transform: rotate(0deg) scale(0.2, 0.2)


			&~ span.mobileToggle:nth-of-type(3)
				transform: rotate(-45deg) translate(2px, -1px)


			&~ .nav__inner
				opacity: 1
				visibility: visible

				ul
					li
						.navLink
							width: 100%
							transition: $transition-default
							padding: .5rem 1rem

							&:before
								content: none

							&:hover, &:focus
								background: $primaryHover
								border: 3px solid transparent


	.header__social
		@extend .flex
		@extend .flex-center--vertical

		@include media-below(tablet)
			display: none

		a
			color: $textLight

